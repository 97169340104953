import { render, staticRenderFns } from "./ScenkonstArticleListLarge.vue?vue&type=template&id=4e3cc127&"
import script from "./ScenkonstArticleListLarge.vue?vue&type=script&lang=ts&"
export * from "./ScenkonstArticleListLarge.vue?vue&type=script&lang=ts&"
import style0 from "./ScenkonstArticleListLarge.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardArticleLarge: require('/tmp/build_0a7f39b8/src/components/card/ArticleLarge.vue').default,BlockWrapper: require('/tmp/build_0a7f39b8/src/components/block/BlockWrapper.vue').default})
