

































import { Component, Prop, Vue } from 'nuxt-property-decorator';

import { ListItem } from '@/graphql/generated';
import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class CardShow extends Vue {
  @Prop({ type: Object, required: true })
  item!: Partial<ListItem>

  nl2br (str: string) {
    return str.replace(/\n/g, '<br>');
  }
}
