























import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { blockColorMixin } from '@/composables/block-color';

import { PageBlockMixin } from '@/modules/cms';
import { medium1x1ImageFragment } from '@/graphql/queries/image.fragments';

@Component({
  graphqlData: gql`
    fragment ScenkonstArticleListLargeBlockData on ScenkonstArticleListLargeBlock {
      title
      lead
      blockColorAttribute {
        bgColor
        textColor
        colorKey
      }
      items {
        title
        lead
        linkText
        linkUrl
        image {
          ...Medium1x1ImageData
        }
      }
    }
    ${medium1x1ImageFragment}
  `,
})
export default class ScenkonstArticleListBlockLargeBlock extends mixins(PageBlockMixin(), blockColorMixin) {}
